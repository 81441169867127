import React from 'react';
import { useLocation } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import PostCard from '../components/post-card';
import PostNotFound from '../components/PostNotFound';
import "../assets/css/post.css";

// Fetch posts based on the page parameter and query string
const fetchPosts = async ({ pageParam = 1, queryString = "" }) => {
  try {
    const token = localStorage.getItem('access_token')
    const header = token ? {Authorization: `Bearer ${token}`} : {};

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/post/filter-posts/?page=${pageParam}&${queryString}`,
      {
        headers: header
      }
    );
  
    if (!response.ok) {
      throw new Error(`Failed to fetch posts`);
    }
    return await response.json();
  } catch (error){
    throw error;
  }
  
};

const PostFilter = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const queryParams = Object.fromEntries(query.entries());
  const queryString = new URLSearchParams(queryParams).toString();

  const {
    data, isLoading,
    isError, hasNextPage,
    fetchNextPage, isFetchingNextPage, } = useInfiniteQuery({
    queryKey: ['posts', queryString], queryFn: ({ pageParam = 1 }) => fetchPosts({ pageParam, queryString }), getNextPageParam: (lastPage) => {
      return lastPage?.next ? lastPage.next.split('page=')[1] : undefined;
    },
  });

  if (isLoading) return <p className="text-center mt-10 text-gray-600 fam-posts">Loading...</p>;
  if (isError) return <p className="text-center mt-10 text-red-600 fam-posts">Error occurred while fetching data.</p>;

  return (
    <>
      <div className="flex-col items-center size-70 fam-posts">
        {data?.pages?.some((page) => page?.results?.length) ? (
          data.pages.map((page) =>
            page.results.map((post) => (
              <PostCard key={post.id} post={post} />
            ))
          )
        ) : (
          <PostNotFound />
        )}

        {/* Load More Button */}
        <div className="flex-col items-center size-70">
          {hasNextPage && (
            <button
              className="load-more-button"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load More'}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PostFilter;
