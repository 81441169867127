
import Modal from "./Modal";
import CustomButton from "./CustomButton";
import React, { useState } from 'react';
import useReportModal from "../hooks/useReportModal";
import { useReportMutation } from "../../services/postApis";

const ReportModal = ({ slug }) => {

  const [reportMutation] = useReportMutation();
  const [selectedOption, setSelectedOption] = useState('');
  const uReportModal = useReportModal()
  
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitReport = async () => {
    console.log('*** selectedOption ***', selectedOption)
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append('report', selectedOption);

    try {
      const response = await reportMutation(formData);
      console.log("*** response *** ", response.data)
      setSelectedOption('');

    } catch (error) {
      console.error("Error:", error);
    }
  };

  const content = (
    <>
      <form >
        <div className="mb-4 text-slate-950 dark:text-stone-200">
          Select the reason for reporting:
          
          <div className="mt-2 grid grid-cols-1 gap-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Fake in Nature"
                checked={selectedOption === "Fake in Nature"}
                onChange={handleOptionChange}
                className="form-radio text-blue-500 h-5 w-5"
              />
              <span className="ml-2">Fake in nature</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="radio"
                value="He has already posted"
                checked={selectedOption === "He has already posted"}
                onChange={handleOptionChange}
                className="form-radio text-blue-500 h-5 w-5"
              />
              <span className="ml-2">He has already posted</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="radio"
                value="lying"
                checked={selectedOption === "lying"}
                onChange={handleOptionChange}
                className="form-radio text-blue-500 h-5 w-5"
              />
              <span className="ml-2">lying</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Inappropriate Content"
                checked={selectedOption === "Inappropriate Content"}
                onChange={handleOptionChange}
                className="form-radio text-yellow-500 h-5 w-5"
              />
              <span className="ml-2">Inappropriate Content</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Spam"
                checked={selectedOption === "Spam"}
                onChange={handleOptionChange}
                className="form-radio text-green-500 h-5 w-5"
              />
              <span className="ml-2">Spam</span>
            </label>
          </div>
        </div>
        
        <div className="flex justify-between">
          <CustomButton 
            label="Submit"  
            onClick={handleSubmitReport}
            className={"bg-blue-500"}
          />
        </div>
      </form>

    </>
  )

  return (
    <Modal 
      isOpen={uReportModal.isOpen}
      close={uReportModal.close}
      label="Report"
      content={content}
    />
  )
}

export default ReportModal


