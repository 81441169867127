
import Modal from "./Modal";
import React from 'react';
import useViewDocumentModal from "../hooks/useViewDocumentModal";

const ViewDocument = ({ url }) => {

  const uViewDocumentModal = useViewDocumentModal()
  
  const content = (
    <>
      <img className="max-w-full max-h-screen"  src={url} alt={"Document"}/>
    </>
  )

  return (
    <Modal 
      isOpen={uViewDocumentModal.isOpen}
      close={uViewDocumentModal.close}
      label="Document"
      content={content}
    />
  )
}

export default ViewDocument


