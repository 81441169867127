import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


// Define a service using a base URL and expected endpoints
export const postApis = createApi({
  reducerPath: 'postApis',
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_BACKEND_DOMAIN + '/post/', 
  }),
  
  endpoints: (builder) => ({
    
    getPostList: builder.query({
      query: (page=1) => {
        return {
          url: `get-posts/?page=${page}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),

    getPostHistory: builder.query({
      query: () => {
        return {
          url: 'get-user-history/',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),

    getPostSatisfied: builder.query({
      query: () => {
        return {
          url: 'get-satisfied-posts/',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      }, 
    }),

    getPostDocFiles: builder.query({
      query: (slug) => {
        return {
          url: `get-post-docs/${slug}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      }, 
    }),

    getPostBankDetails: builder.query({
      query: (slug) => {
        return {
          url: `get-post-bank-details/${slug}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      }, 
    }),

    getPostSaves: builder.query({
      query: () => {
        return {
          url: 'get-user-save-posts/',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),

    getPostDetail: builder.query({
      query: (slug) => {
        return {
          url: `detail-post/${slug}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),

    createPost: builder.mutation({
      query: (data) => {
        return {
          url: 'create-post/',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
            'Content-Type': 'multipart/form-data',
          },
          body: data
        }
      },

    }),

    uploadFile : builder.mutation({
      query: (formData) => {
        console.log('***** formData', formData)
        return {
          url: 'upload-file/',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
            'Content-Type': 'multipart/form-data',
          },
          body: formData,
        }
      },
    }),

    upvote : builder.mutation({
      query: (slug) => {
        return {
          url: `upvote-post/${slug}/`,
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
            'Content-Type': 'multipart/form-data',
          },
        }
      },
    }),

    downvote : builder.mutation({
      query: (slug) => {
        return {
          url: `downvote-post/${slug}/`,
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
            'Content-Type': 'multipart/form-data',
          },
        }
      },
    }),

    save : builder.mutation({
      query: (slug) => {
        return {
          url: `save-post/${slug}/`,
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
            'Content-Type': 'multipart/form-data',
          },
        }
      },
    }),

    comment : builder.mutation({
      query: (formData) => {
        return {
          url: 'create-comment/',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          },
          body: formData
        }
      },
    }),

    getComments: builder.query({
      query: (slug) => {
        return {
          url: `get-post-comments/${slug}`,
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),

    search: builder.query({
      query: (query) => {
        return {
          url: 'search-posts/',
          method: 'GET',
          params: {query},
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),


    report : builder.mutation({
      query: (data) => {
        return {
          url: 'report-post/',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          },
          body: data
        }
      },
    }),

    donate : builder.mutation({
      query: (data) => {
        return {
          url: 'donate-post/',
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          },
          body: data
        }
      },
    }),

    filter: builder.query({
      query: (query) => {
        return {
          url: 'filter-posts/',
          method: 'GET',
          params: {query},
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),

    profilePost: builder.query({
      query: () => {
        return {
          url: 'profile-posts/',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    }),


    supportHistory: builder.query({
      query: () => {
        return {
          url: 'donate-history/',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token') }`,
          }
        }
      },
    })
  }),
})

export const { 
    useGetPostListQuery, useGetPostHistoryQuery, useGetPostSatisfiedQuery, useGetPostSavesQuery, useGetPostDetailQuery, useGetPostDocFilesQuery, useCreatePostMutation, useUploadFileMutation,
    useUpvoteMutation, useDownvoteMutation, useSaveMutation, useCommentMutation, useGetCommentsQuery, useSearchQuery, useReportMutation, useDonateMutation, useFilterQuery, useProfilePostQuery, useSupportHistoryQuery, useGetPostBankDetailsQuery,

} = postApis

