import { useInfiniteQuery } from '@tanstack/react-query';
import PostCard from '../components/post-card';
import "../assets/css/post.css";

// Fetch posts based on the page parameter
const fetchPosts = async ({ pageParam = 1 }) => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/post/report-history/?page=${pageParam}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    },
  });
  const data = await response.json();
  return data;
};

const ReportHistory = () => {
  const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, } = useInfiniteQuery({queryKey: ['posts'],queryFn: fetchPosts, getNextPageParam: (lastPage) => lastPage.next ? lastPage.next : undefined,});

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Something went wrong...</p>;

  return (
    <>
      <div className="flex-col items-center size-70 fam-posts">
        {data.pages.map((page) =>
          page.results.map((post) => (
            <PostCard key={post.id} post={post} />
          ))
        )}
        {/* Load More Button */}
        <div className="flex-col items-center size-70">
          {hasNextPage && (
            <button
              className="load-more-button"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load More'}
            </button>
          )}
        </div>
      </div>
      
    </>
  );
};

export default ReportHistory;
