import { useInfiniteQuery } from '@tanstack/react-query';
import PostCard from '../components/post-card';
import "../assets/css/post.css";

const fetchPosts = async ({ pageParam = 1 }) => {
  try {
    const token = localStorage.getItem('access_token');
    const headers = token
      ? { Authorization: `Bearer ${token}` }
      : {};

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/post/get-posts/?page=${pageParam}`,
      {
        headers,
      }
    );

    if (!response.ok) {
      // Include response details for better error handling
      throw new Error(`Failed to fetch posts: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error; // Re-throw error to handle it further up the chain
  }
};

const PostHome = () => {
  const { 
    data, 
    isLoading, 
    isError, 
    hasNextPage, 
    fetchNextPage, 
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['posts'],
    queryFn: fetchPosts,
    getNextPageParam: (lastPage) => lastPage?.next ? lastPage.next : undefined,
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Something went wrong...</p>;

  return (
    <>
      <div className="flex-col  items-center  fam-posts">
        {data?.pages?.map((page, pageIndex) => (
          page?.results?.map((post) => (
            <PostCard key={post.id} post={post} />
          )) || <p key={pageIndex}>No posts available</p>
        ))}
        {/* Load More Button */}
        <div className="flex-col items-center size-70">
          {hasNextPage && (
            <button
              className="load-more-button"
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? 'Loading more...' : 'Load More'}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default PostHome;
