import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { multiFormatDateString } from '../utils/DateConvertor';
import "../assets/css/post.css";
import CommentList from './comments';

import { Link } from 'react-router-dom';
// buttons
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { BiDownvote, BiSolidDownvote } from "react-icons/bi";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import '../assets/css/comments.css';

// Modals
import DonorsModal from '../modals/DonorsModal';
import ReportModal from '../modals/ReportModal';
import SupportSeekerModal from '../modals/SupportSeekerModal';
import ViewDocument from '../modals/ViewDocument';

// Hooks
import useDonorsModal from '../hooks/useDonorsModal';
import useReportModal from '../hooks/useReportModal';
import useSupportSeekerModal from '../hooks/useSupportSeekerModal';
import useViewDocumentModal from '../hooks/useViewDocumentModal';

import { useGetPostDetailQuery, useGetPostDocFilesQuery, 
        useDownvoteMutation, useUpvoteMutation, useSaveMutation, useCommentMutation, useGetCommentsQuery } from '../../services/postApis';


const PostDetailView = () => {

  const { slug } = useParams();
  const { data, isError, error, isLoading } = useGetPostDetailQuery(slug);
  console.log("data", data)
  const { data: docData } = useGetPostDocFilesQuery(slug);
  const uDonorsModal = useDonorsModal();
  const uReportModal = useReportModal();
  const uSupportSeekerModal = useSupportSeekerModal();
  const uViewDocumentModal = useViewDocumentModal();


  const [upvote, setUpvotes] = useState(0)
  const [downvote, setDownvote] = useState(0)
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const { data: commentData } = useGetCommentsQuery(slug);
  const [commentCount, setCommentCount] = useState(0);



  const [selectedDoc, setSelectedDoc] = useState(null);
  const openDocumentModal = (docKey) => {
    setSelectedDoc(docData.documents[docKey]);
    uViewDocumentModal.open();
  };


  useEffect(() => {
    if (data) {
      setUpvotes(data.upvote_count);
      setDownvote(data.downvote_count);
      setIsUpvoted(data.is_upvoted);
      setIsDownvoted(data.is_downvoted);
      setIsSaved(data.is_saved);
      setCommentCount(data.comment_count);
    }
  }, [data]);

  useEffect(() => {
    if (commentData) {
      setCommentList(commentData);
    }
  }, [commentData]);

  const [upvoteMutation] = useUpvoteMutation()
  const [downvoteMutation] = useDownvoteMutation()
  const [saveMutation] = useSaveMutation()
  const [commentMutation] = useCommentMutation()

  const handleUpvote = (event) => {
    event.stopPropagation();
    if (!isUpvoted) {
      setUpvotes(upvote + 1)
      setIsUpvoted(true)
      if (isDownvoted) {
        setDownvote(downvote - 1)
        setIsDownvoted(false)
      };
      upvoteMutation(slug)
    } else {
      setUpvotes(upvote - 1)
      setIsUpvoted(false)
      upvoteMutation(slug)
    }
  }

  const handleDownvote = (event) => {
    event.stopPropagation();

    if (!isDownvoted) {
      setDownvote(downvote + 1)
      setIsDownvoted(true)
      if (isUpvoted) {
        setUpvotes(upvote - 1)
        setIsUpvoted(false)
      };
      downvoteMutation(slug)
    } else {
      setDownvote(downvote - 1)
      setIsDownvoted(false)
      downvoteMutation(slug)
    }
  }

  const handleSave = (event) => {
    if (!isSaved) {
      setIsSaved(true)
      saveMutation(slug)
    } else {
      setIsSaved(false)
      saveMutation(slug)
    }
  }

  const handleSubmitComment = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("body", event.target.body.value);
    formData.append("slug", event.target.slug.value);

    try {
      const response = await commentMutation(formData);
      setCommentList([...commentList, response.data]);
      setCommentCount(commentCount + 1);
      event.target.reset();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (isLoading) {
    return <div><CircularProgress />Loading...</div>;
  }
  if (isError) return <div>Error: {error.message}</div>;
  
  return (
    <div className='bg-white PostDetailView dark:bg-gray-700 text-dark-1 dark:text-gray-100 m-12 max-w-7xl p-5'>
      <div className='grid grid-cols-2 gap-4'>
        <div className=''>
          {data?.post_videos?.videos?.seeker_video? (
            <video className='detailVideo' controls controlsList="nodownload">
              <source src={data.post_videos.videos.seeker_video} type="video/mp4" />
            </video>
          ) : (
            <div className='flex justify-center items-center bg-gray-700 text-white opacity-80 m-10'>
              <span>Videos is being uploaded</span>
            </div>
          )}
        </div>
        <div className=''>
          {data?.post_videos?.videos?.place_video? (
            <video className='detailVideo' controls controlsList="nodownload">
              <source src={data.post_videos.videos.place_video} type="video/mp4" />
            </video>
          ) : (
            <div className='flex justify-center items-center bg-gray-700 text-white opacity-80 m-10'>
              <span>Videos is being uploaded</span>
            </div>
          )}
        </div>
      </div>

      <div className='flex justify-between py-4'>

        <div className='flex'>
          <Link className='custom-link'
            to={`/profiles/${data.creator}`}>
            <p className='text-xs font-bold text-blue-500'>{data.creator}</p>
          </Link>

          <Link className='custom-link' onClick={handleUpvote}>
            {isUpvoted ? <BiSolidUpvote className='text-xl text-green-500' /> : <BiUpvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500'>{upvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleDownvote}>
            {isDownvoted ? <BiSolidDownvote className='text-xl' /> : <BiDownvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500'>{downvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleSave}>
            {isSaved ? <FaBookmark className='text-xl' /> : <FaRegBookmark className='text-xl' />}
          </Link>
        </div>

        <div className="flex justify-center space-x-4">
          <button
            onClick={uDonorsModal.open}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded"
          >
            Donate ({data.donors_count})
          </button>

          <button
            onClick={uReportModal.open} 
            className="bg-teal-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded">
            Report ({data.report_count})
          </button>

          <button
            onClick={uSupportSeekerModal.open} 
            className="bg-slate-400 hover:bg-green-600 text-white font-bold py-1 px-2 rounded">
            Support them
          </button>
        </div>

        



        {/* Render the Donate Modal */}
        <DonorsModal slug={data.slug} />
        <ReportModal slug={data.slug} />
        <SupportSeekerModal slug={data.slug}/>


      </div>

        
      <div className="space-y-3 py-4">
        <h2 className="text-2xl font-semibold">Needy Details</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white py-1 px-2 rounded mx-2 hover:text-black hover"
            >
                Name: {data.seeker}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white  py-1 px-2 rounded mx-2 hover:text-black hover"
            >
                Need: {data.need}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white  py-1 px-2 rounded mx-2 hover:text-black hover"
            >
               Address: {data.address}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded mx-2 hover:text-black hover"
            >
              phone_number: {data.phone_number}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded mx-2 hover:text-black hover"
            >
              Created at: {multiFormatDateString(data.created)}
            </div>
        </div>
      </div>


      <div className="space-y-3 py-4">
        <h2 className="text-2xl font-semibold">Documents</h2>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          

          {docData &&
            docData.documents &&
            Object.keys(docData.documents).map((docKey) => (
              
              <button
                key={docKey}
                onClick={() => openDocumentModal(docKey)}
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-bold py-1 px-2 rounded mx-2 hover:text-black hover"
              >
                {docKey.replace(/_/g, ' ')}
              </button>
            ))}

          {/* Render the modal only when it's open */}
          {uViewDocumentModal.isOpen && <ViewDocument url={selectedDoc} />}
        </div>
      </div>

      <div className='space-y-3 py-4'>
        <div className='text-2xl font-bold'>Discription</div>
        <p className='text-dark-1 dark:text-white'>{data.description}</p>
      </div>

      <div className='py-4 bg-white dark:bg-gray-700 '>
        <p className='text-2xl font-bold text-dark-1 dark:text-white'>{commentCount} Comments</p>

        <form onSubmit={handleSubmitComment} className=' bg-white dark:bg-gray-700 comment-form  '>
          <input type='text' placeholder='Add a comment...' name='body' className=' text-dark-1 dark:text-white bg-white dark:bg-gray-700' />
          <input type='hidden' value={data.slug} name='slug' />
          <button type='submit'>Comment</button>
        </form>

        {commentList && commentList.length > 0 ? (
            [...commentList].reverse().map(comment => (
                <CommentList key={comment.id} comment={comment} />
            ))
        ) : (
            <p>No comments yet.</p>
        )}


      </div>

    </div>
  );
}

export default PostDetailView
