const CustomButton = ({label, onClick, className}) => {
  return (
    <div 
      onClick={onClick}
      className={`w-full py-4 text-white rounded-xl transition cursor-pointer ${className}`}>
      <div className="flex justify-center font-bold text-lg">
        {label}
      </div>
      
    </div>
  )
}

export default CustomButton