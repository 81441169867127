
import Modal from "./Modal";
import React from 'react';
import useDonorsModal from "../hooks/useDonorsModal";



const DonorsModal = ({ slug }) => {

  const DonorsModal = useDonorsModal();


  const content = (
    <>
      
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-4">
        <div className="text-slate-950 dark:text-stone-200">
          <p>
            
          </p>
        </div>
        
      </div>

    </>
  )

  return (
    <Modal 
      isOpen={DonorsModal.isOpen}
      close={DonorsModal.close}
      label="Donation"
      content={content}
    />
  )
}

export default DonorsModal